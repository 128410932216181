<template>
  <div class="passwordReset">
    <div class="reset-form">
      <div>
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="70px"
          size="small"
        >
          <el-form-item label="" prop="password">
            <div class="reset-title mb_20">
              <h2>密码重置</h2>
            </div>
          </el-form-item>

          <el-form-item label="手机号" prop="mobile">
            <el-input
              maxlength="11"
              style="width: 100%"
              placeholder="请输入手机号"
              v-model="ruleForm.mobile"
            ></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="smsCode">
            <el-input
              maxlength="6"
              style="width: 100%"
              placeholder="请输入验证码"
              v-model="ruleForm.smsCode"
            >
              <template slot="append">
                <span
                  class="obtain-code"
                  v-if="!codeState"
                  @click="getObtainCode"
                  >获取验证码</span
                >
                <span v-if="codeState" style="color: #bebebe"
                  >还剩{{ codeTime }}秒</span
                >
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="新密码" prop="password">
            <el-input
              style="width: 100%"
              placeholder="请输入新密码"
              v-model="ruleForm.password"
              show-password
            ></el-input>
          </el-form-item>

          <el-form-item label="" prop="password">
            <div class="form-button">
              <el-button style="padding:0;" type="text" @click="toLogin">返回登录</el-button>
              <el-button type="primary" @click="confirm('ruleForm')">提交</el-button>
            </div>
          </el-form-item>
        </el-form>
      </div>
      
    </div>
  </div>
</template>

<script>
import { JSEncrypt } from "jsencrypt";
import { resetPasswordSmsCode, resetPassword } from "@/api/login";
export default {
  name: "passwordReset",

  data() {
    let checkPhone = (rule, value, callback) => {
      let reg = /^1[345789]\d{9}$/;
      if (!reg.test(value)) {
        callback(new Error("请输入11位手机号"));
      } else {
        callback();
      }
    };
    let checkCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"));
      } else {
        callback();
      }
    };
    let checkPassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        mobile: "",
        smsCode: "",
        password: "",
      },
      rules: {
        mobile: [
          {
            type: "number",
            validator: checkPhone,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
        ],
        smsCode: [
          {
            validator: checkCode,
            message: "请输入正确的验证码",
            trigger: "blur",
          },
        ],
        password: [
          {
            validator: checkPassword,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      codeState: false,
      codeTime: 60,
      timer: null,
    };
  },

  mounted() {},

  methods: {
    toLogin(){
      this.$router.replace("/login");
    },
    confirm(ruleForm){
      this.$refs[ruleForm].validate(async (valid) => {
        if (valid) {
          const param = {
            mobile: this.ruleForm.mobile,
            smsCode: this.ruleForm.smsCode,
            password: this.passwordEncryption(this.ruleForm.password)
          }
          const {code} = await resetPassword(param)
          if(code == 200){
            this.$message.success("已重置，请使用新密码登录");
            setTimeout(()=>{
              this.$router.replace("/login");
            }, 1000)
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    passwordEncryption(passwordUser) {
      let publicKey =
        "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC1qsjXu6Oblc7W6B/q0+NXUYJ3dN9X4BZ3xncrKJEfCBwS5IE3ZgABQe2MR0fagpfKahtUaeVjLRGJh6A23yyHewxI6kfuAc+/oTxZLJia6K/l46JbLwb96DatpTa9sAk+5MS6IZ94fbaQ8iv9rn+h3Ddo9MDoPW+/sVtWtv9lbwIDAQAB";
      let encryptor = new JSEncrypt();
      encryptor.setPublicKey(publicKey);
      let passwordEncryp = encryptor.encrypt(passwordUser);
      return passwordEncryp;
    },
    async getObtainCode() {
      var that = this;
      if (this.ruleForm.mobile === "") {
        this.$refs.ruleForm.validateField("mobile");
      } else {
        const param = {
          mobile: this.ruleForm.mobile,
        };
        const { code } = await resetPasswordSmsCode(param);
        if (code == 200) {
          this.codeState = true;
          that.timer = setInterval(() => {
            if (that.codeTime == 0) {
              clearInterval(that.timer);
              that.codeState = false;
              that.codeTime = 60;
            } else {
              that.codeTime--;
            }
          }, 1000);
        } else {
          that.codeState = false;
          that.codeTime = 60;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.passwordReset {
  padding: 20px;
  .reset-title{
    text-align: center;
  }
  .reset-form {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .obtain-code {
      color: #1677ff;
      cursor: pointer;
    }
    .form-button{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }
  }
}
</style>